<template>
  <div class="animated fadeIn">
  <b-row>
    <b-col cols="12" lg="12">
      <b-card class="w-100" no-header>
        <template slot="header">
          <span v-if="quote" class="mt-2">
            <b-button v-if="quote.freeze === false" @click="refreshIgnitePolicy" class="mr-4">Reload Policy Data</b-button>
            <b-button 
              v-if="quote.freeze === false"
              @click="freezeIgnitePolicy(true);" 
              class="mr-4" 
              variant="danger"
            >Freeze Policy Data</b-button>
            <b-button 
              v-else
              @click="freezeIgnitePolicy(false);" 
              class="mr-4" 
              variant="success"
            >Un-Freeze Policy Data</b-button>
            Reference:  {{ quote.policy_number }} </span>
          <b-button @click="goBack" class="btn-sm pull-right">Back</b-button>
        </template>

        <b-card v-if="loaded" no-body id="data">

          <b-tabs card>

            <b-tab v-for="(tab, index) in quote.data" :key="tab.name" :title="tab.title" :class="{ 'active': index === 0 }" class="mt-2">

              <div>

                <div v-if="tab.type === 'html'" v-html="tab.data">

                  {{ tab.data }}

                </div>

                <div v-else>

                  <table class="table">
                    
                    <tr v-for="(item, key, index) in tab.data" :key="index">
                      <th v-if="key !== 'customer_id'">
                        {{ key | capitalize }}
                      </th>
                      <td v-if="key === 'customer'">
                        <router-link :to="{name: 'Customer', params: {id: quote.data[0].data.customer_id}}">{{ item }}</router-link>
                      </td>
                      <td v-else-if="key !== 'customer_id'" v-html="item">
                        {{ item }}
                      </td>
                    </tr>
                  </table>

                </div>

              </div>

            </b-tab>

            <b-tab v-if="quote.limits.length > 0" title="Limits">

              <b-tabs card>

                <b-tab v-for="(pet, index) in quote.limits" :key="index" :title="pet.name">

                  <table class="table">

                    <tr>
                      <th>Section</th>
                      <th>Type</th>
                      <th>Description</th>
                      <th>Limit</th>
                      <th>Remaining</th>
                    </tr>

                    <tr v-for="(limit, indexJ) in pet.limits" :key="indexJ">
                      <template v-if="limit.limit > 0">
                        <td>{{limit.section}}</td>
                        <td>{{limit.limit_type}}</td>
                        <td>{{limit.type}}<br/>{{limit.payout_type}}</td>
                        <td v-if="limit.amount_type === 'decimal'">&pound;{{limit.limit}}</td>
                        <td v-else-if="limit.amount_type === 'percentage'">{{limit.limit}}%</td>
                        <td v-if="limit.hasOwnProperty('remaining')">&pound;{{limit.remaining}}</td>
                        <td v-else>---</td>
                      </template>
                      <template v-else>
                        <td class="bg-danger text-dark" style="opacity: 0.5;">{{limit.section}}</td>
                        <td class="bg-danger text-dark" style="opacity: 0.5;">{{limit.limit_type}}</td>
                        <td class="bg-danger text-dark" style="opacity: 0.5;">{{limit.type}}<br/>{{limit.payout_type}}</td>
                        <td class="bg-danger text-dark" style="opacity: 0.5;">N/A</td>
                        <td class="bg-danger text-dark" style="opacity: 0.5;">N/A</td>
                      </template>
                    </tr>

                  </table>

                </b-tab>

              </b-tabs>

            </b-tab>

            <notes-tab v-if="quote" v-bind:type="'policy'" v-bind:object="quote.id"></notes-tab>

            <b-tab v-if="quote" title="Claims">
              <!--<b-button class="mb-2" @click="loginAsCustomer">Make A Claim On Behalf Of Customer</b-button>-->
              <emulate :id="quote.customer" type="Customer"/><br/>
              <b-button class="mb-2" :to="{name: 'Search Claim', params: {policy_reference: quote.policy_number}}">View Claims On This Policy</b-button>
            </b-tab>

          </b-tabs>

        </b-card>

        <template slot="footer">
          <b-button @click="goBack" class="float-left">Back</b-button>
        </template>
      </b-card>
    </b-col>
  </b-row>

    <b-toast id="document-toast" variant="info" solid>
      <template v-slot:toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <strong class="mr-auto">PDF Downloaded</strong>
          <small class="text-muted mr-2">now</small>
        </div>
      </template>
      PDF is ready.
      <a target="_blank" v-if="quote" :href="pdf_link" :download="quote.policy_number + '.pdf'">Download document</a>
    </b-toast>

    <b-toast id="email-document-toast" variant="success" solid>
      <template v-slot:toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <strong class="mr-auto">PDF Email Sent</strong>
          <small class="text-muted mr-2">now</small>
        </div>
      </template>
      PDF was sent via email.
    </b-toast>


    <b-modal
      id="email-modal"
      ref="email-modal"
      centered
      title="Select recipient of Schedule PDF"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          :state="toEmailState"
          label="Recipient of email"
          label-for="to-email-input"
          invalid-feedback="This field is required"
        >
          <b-form-select
            id="to-email-input"
            v-model="to_email"
            :state="toEmailState"
            :options="email_options"
            required
          ></b-form-select>
        </b-form-group>
      </form>
      <template v-slot:modal-footer="{ ok, cancel, hide }">
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <b-button size="md" variant="outline-dark" @click="cancel()">
        Cancel
      </b-button>
      <b-button size="md" variant="success" :disabled="sending_email" @click="ok()">
        <span v-show="!sending_email">Confirm & Send</span> <span v-show="sending_email">Sending <i  class="fas fa-spinner fa-spin ml-2"></i></span>
      </b-button>
    </template>
    </b-modal>

  </div>

</template>

<script>
  import axios from '../../../shared/axios/auth'
  import Id from "bootstrap-vue/src/mixins/id";
  import notesTab from '../utils/tabs/notes'
    import EmulateCustomer from "../../EmulateCustomerButton";

  var jwt = require('jwt-simple');

  export default {
    name: 'Policy',
    components: {Id,
      'notes-tab': notesTab,
      "emulate": EmulateCustomer
    },
    props: {
      caption: {
        type: String,
        default: 'Policy Reference '
      },
      id: {
        type: Number,
        default: 0
      }
    },
    data: () => {
      return {
        loaded: false,
        quote: null,
        pdf: null,
        pdf_link: null,
        get_document: false,
        to_email: '',
        toEmailState: null,
        email_options: [],
        sending_email: false,
      }
    },
    methods: {
      goBack() {
        this.$router.go(-1)
      },
      getPolicy() {

        let url = null;
        let id = this.id;
        if (id === 0) {
          id = this.$route.params.id;
        }
        if (localStorage.getItem('selected_product')) {
          url = '/policies/' + id + '/?product=' + localStorage.getItem('selected_product');
        } else {
          url = '/policies/' + id + '/';
        }

        axios.get(url).then(
          response => {
            this.quote = response.data
            this.email_options = response.data.email_documentation_emails
            this.loaded = true
          }
        ).catch(error => {

            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }

            console.log('premium error');
            console.log(error.response)
          })
        return this.quote
      },
      freezeIgnitePolicy(freeze) {
        let url = null;
        let id = this.id;
        if (id === 0) {
          id = this.$route.params.id;
        }
        if (localStorage.getItem('selected_product')) {
          url = '/policies/' + id + '/?product=' + localStorage.getItem('selected_product');
        } else {
          url = '/policies/' + id + '/';
        }

        let data = {
          freeze: freeze
        }

        axios.patch(url, data).then(
          response => {
            //this.quote = response.data;
            this.getPolicy();
          }
        ).catch(error => {

            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }

            console.log('premium error');
            console.log(error.response)
          })
        return this.quote
      },
      refreshIgnitePolicy() {

        let url = '/policies/load-ignite/';
        let data = {
          policy_number: this.quote.policy_number
        }

        axios.post(url, data).then(
          response => {
            alert("refreshed");
            this.getPolicy();
          }
        ).catch(error => {
            alert("error");
            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }

            console.log('premium error');
            console.log(error.response)
          })
        return this.quote
      },
      b64toBlob(b64Data, contentType, name) {
        contentType = contentType || '';
        var sliceSize = 512;
        b64Data = b64Data.replace(/^[^,]+,/, '');
        b64Data = b64Data.replace(/\s/g, '');
        var byteCharacters = window.atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, {type: contentType, filename: name});
        return blob;
      },
      async getDocumentation() {

        this.get_document = true;

        try {
          const pdf_response = await axios.get('/policies/documentation/' + this.$route.params.id + '/').catch(
            error => {

              // Unauthorised
              if (error.response.status === 401) {
                this.$store.dispatch('logout');
              }
              console.log('document error');
              console.log(error.response)
              this.get_document = false;
          })

          //let blob = new Blob([base64url.decode(pdf_response.data.data)], { type: "application/pdf", filename: this.quote.policy_number + ".pdf" });

          let blob = this.b64toBlob(pdf_response.data.data, "application/pdf", this.quote.policy_number + ".pdf");

          let blobUrl = URL.createObjectURL(blob);

          window.open(blobUrl, '_blank');

          //window.open(blobUrl);

          this.pdf_link = blobUrl; //"data:application/pdf;base64," + pdf_response.data.data

          this.$bvToast.show('document-toast')

        } catch(err){
          this.get_document = false;
          console.log(err)
        }

      },

      checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        this.toEmailState = valid
        return valid
      },
      resetModal() {
        this.to_email = ''
        this.toEmailState = null
      },
      handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
      },
      handleSubmit() {
        this.sending_email = true
        // Exit when the form isn't valid
        if (!this.checkFormValidity()) {
          this.sending_email = false
          return
        }
        // Send to server

        let data = {};
        data.to_email = this.to_email

        axios.post('/policies/email-documentation/' + this.$route.params.id + '/', data).then(response => {

              // Unauthorised
              if (response.status === 401) {
                this.$store.dispatch('logout');
              }
              console.log('email document error');
              console.log(response.data)

              this.$bvToast.show('email-document-toast')

              this.sending_email = false;
              this.$refs['email-modal'].hide()

        }).catch(
            error => {
              this.sending_email = false

              // Unauthorised
              if (error.response.status === 401) {
                this.$store.dispatch('logout');
              }
              console.log('email document error');
              console.log(error.response)

          })



        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-prevent-closing')
        })
      },
    },
    computed: {
      hasAccess() {

        let access = ['full_admin', 'customer_services'];

        let allowed = false;

        access.forEach(item => {
          if (this.$store.getters.getUserPermissions.includes(item)) {
            allowed = true
          }
        })

        return allowed

      }
    },
    mounted() {
      this.getPolicy()
    },
    watch: {
      $route(to, from) {
        // react to route changes...
        this.$router.replace(to.fullPath).catch(error => {
          console.log(error)
        }).then(
          this.getPolicy()
        );

      }
    }
  }
</script>

<style>

  #data .card-header {
    padding-top: 1.5rem;
  }
</style>
